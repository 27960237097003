import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../Http/request";

export const adminDashboardDetails = createAsyncThunk(
  "admin-dashboard/admindashboard",
  async (params, thunkAPI) => {
    try {
      const response = await get("/admin/dashboard");
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUsers = createAsyncThunk(
  "admin/users/all_users",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/users?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReferrals = createAsyncThunk(
  "admin/all_referrals",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/referrals?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDeposits = createAsyncThunk(
  "admin/deposit/all_deposits",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/deposit?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getWithdrawals = createAsyncThunk(
  "admin/withdrawal/all_withdrawals",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/withdrawals?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRequest = createAsyncThunk(
  "admin/requests",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/request?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "admin/blogs",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/blog?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUpdates = createAsyncThunk(
  "admin/updates",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/updates?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createBlogs = createAsyncThunk(
  "admin/blogs/create",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/admin/blog/create`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createUpdate = createAsyncThunk(
  "admin/update/create",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/admin/updates/create`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "admin/notifications",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/notifications?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getActivities = createAsyncThunk(
  "admin/activities",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/activity?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAddress = createAsyncThunk(
  "admin/address",
  async (params: { page: number } | null, thunkAPI) => {
    try {
      const response = await get(
        `/admin/address?page=${params ? params.page : 1}`
      );
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditCrypto = createAsyncThunk(
  "edit/crypto",
  async (params: object, thunkAPI) => {
    try {
      const response = await post("/admin/coin", params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Approve_Decline_Deposit = async (params: Action) => {
  try {
    if (params.action === "APPROVE") {
      const response = await post("/admin/deposit/approve", params, {});
      return response;
    }
    const response = await post("/admin/deposit/decline", params, {});
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Approve_Decline_Request = async (params: Action) => {
  try {
    if (params.action === "APPROVE") {
      const response = await post("/admin/request", params, {});
      return response;
    }
    const response = await post("/admin/request/decline", params, {});
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteBlog = async (params: Action) => {
  try {
    const response = await post("/admin/blog/delete", params, {});
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateBlog = createAsyncThunk(
  "admin/blogs/update",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/admin/blog`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateNotifier = createAsyncThunk(
  "admin/notifier/update",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/admin/updates`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ViewBlog = async (params: string) => {
  try {
    const response = await get(`/admin/blog/view?id=${params}`);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const ViewAccount = async (params: string) => {
  try {
    const response = await get(`/admin/users/account?id=${params}`);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteAccount = async (params: string) => {
  try {
    const response = await post(
      `/admin/users/account/delete`,
      { id: params },
      {}
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateAccount = createAsyncThunk(
  "admin/account/update",
  async (params: object, thunkAPI) => {
    try {
      const response = await post(`/admin/users/account`, params, {});
      return response;
    } catch (error: any) {
      const message = error.message.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
